import axios from 'axios';

const bundleId = 'today.webtoon.play.clumsy240909';

export const putImage = async (path: `/${string}`, imageComponent: HTMLInputElement, onProgress = ()=>{}) => {
    let ret = null;

    if (imageComponent.files === null){
        return null;
    }

    let S3Signed = await axios.put(`https://play-api.webtoon.today/storage${path}`, { bundleId }, {
        headers: {
            //Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    })

    let res = await axios.put(S3Signed.data.data.uploadURL, imageComponent.files[0], {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        withCredentials: true
    })

    if (res.status === 200){
        ret = `https://play.webtoon.today/${S3Signed.data.data.Key}`;
    }

    return ret;
}

export const putScript = async (path: `/${string}`, script: string, onProgress = ()=>{}) => {
    let formData = new FormData();
    let ret = null;

    let S3Signed = await axios.put(`https://play-api.webtoon.today/storage${path}/data.json`, { bundleId }, {
        headers: {
            //Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    })

    formData.append('file', script)
    
    let res = await axios.put(S3Signed.data.data.uploadURL, script, {
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: true
    })

    if (res.status === 200){
        ret = `https://play.webtoon.today/${bundleId}/${S3Signed.data.data.Key}`;
    }

    return ret;
}