import { useState } from "react";

import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { alignments } from "../Editor";


export const ChatType: React.FC<{ fields: any; setFields: (value: any) => void;}> = ({ fields, setFields }) => {
    const [chatList, setChatList] = useState(Array.isArray(fields)? fields : [{ data: "", meta: { alignment: "upperLeft" } }]);

    const handleChatChange = (index: number, key: string, value: any) => {
        const updatedChats = [...chatList];
        updatedChats[index] = { ...updatedChats[index], [key]: value };
        setChatList(updatedChats);
        const removeEmptyChats = updatedChats.filter((chat) => chat.data !== "" && chat.data);
        setFields(removeEmptyChats);
    };

    const addChat = () => {
        const newChat = { data: "", meta: { alignment: "upperLeft" } };
        const updatedChats = [...chatList, newChat];
        setChatList(updatedChats);
        const removeEmptyChats = updatedChats.filter((chat) => chat.data !== "" && chat.data);
        setFields(removeEmptyChats);
    };

    const deleteChat = (index: number) => {
        const updatedChats = chatList.filter((_:any, i:number) => i !== index);

        setChatList(updatedChats);
        const removeEmptyChats = updatedChats.filter((chat: any) => chat.data !== "" && chat.data);
        setFields(removeEmptyChats);
    };
    
    return (
        <Box>
            {chatList.map((chat: any, index: number) => (
                <Box key={index} display="flex" alignItems="center" marginBottom={2} padding={1} border="1px solid #ccc">
                    <Box flexGrow={1}>
                        <TextField
                            label={`채팅 ${index + 1}`}
                            value={chat.data}
                            onChange={(e) => handleChatChange(index, "data", e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <FormControl fullWidth margin="normal" className="FormControl">
                            <InputLabel>정렬</InputLabel>
                            <Select
                                label="정렬"
                                value={chat.meta?.alignment || "upperLeft"}
                                onChange={(e) => handleChatChange(index, "meta", { ...chat.meta, alignment: e.target.value })}
                            >
                                {alignments.map((alignment) => (
                                    <MenuItem value={alignment} key={alignment}>
                                        {alignment}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box marginLeft={2}>
                        <IconButton onClick={() => deleteChat(index)}>
                            <Delete />
                        </IconButton>
                    </Box>
                </Box>
            ))}
            <Button fullWidth variant="outlined" onClick={addChat}>
                채팅 추가
            </Button>
        </Box>
    );
};
