import { useState } from "react";

import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { alignments } from "../Editor";

type Choice = {question: string, image: string, data: {message: {data: "", meta: {alignment: 'left'|'middle'|'right'}} }[] };
export const ChoiceType: React.FC<{ fields: Choice; setFields: (value: Choice) => void; }> = ({ fields, setFields }) => {
    const [innerFields, _setInnerFields] = useState(fields || { question: "", image: "", data: [] });

    const setInnerFields = (value: any) => {
        _setInnerFields(value);
        setFields(value);
    }


    const cleanEmptyFields = (obj: any): any => {
        return Object.keys(obj).reduce((acc: Record<string, any>, key) => {
            if (Array.isArray(obj[key])) {
                acc[key] = obj[key].filter((item: any) => {
                    return Object.values(item).some((value) => value !== "" && value !== null && value !== undefined);
                }).map((item: any) => cleanEmptyFields(item));
            } else if (obj[key] !== "" && obj[key] !== null && obj[key] !== undefined) {
                acc[key] = obj[key];
            }
            return acc;
        }, {} as Record<string, any>);
    };

    const handleChoiceChange = (index: number, key: string, value: any) => {
        const updatedChoices = { ...innerFields };
        if (key === "message") {
            updatedChoices.data[index] = {
                ...updatedChoices.data[index],
                message: { ...updatedChoices.data[index].message, data: value.data, meta: value.meta }
            };
        } else {
            updatedChoices.data[index] = { ...updatedChoices.data[index], [key]: value };
        }
        const cleanedChoices = cleanEmptyFields(updatedChoices);
        setInnerFields(cleanedChoices);
    };

    const handleMetaChange = (index: number, alignment: 'left'|'middle'|'right') => {
        const updatedChoices = { ...innerFields };
        updatedChoices.data[index].message.meta.alignment = alignment;
        const cleanedChoices = cleanEmptyFields(updatedChoices);
        setInnerFields(cleanedChoices);
    };

    const addChoice = () => {
        const newChoice = { message: { data: "", meta: { alignment: "left" } }, objectId: "", branchId: "" };
        const updatedChoices = { ...innerFields, data: [...innerFields.data, newChoice] };
        const cleanedChoices = cleanEmptyFields(updatedChoices);
        setInnerFields(cleanedChoices);
    };

    const deleteChoice = (index: number) => {
        const updatedChoices = { ...innerFields, data: innerFields.data.filter((_: any, i: number) => i !== index) };
        const cleanedChoices = cleanEmptyFields(updatedChoices);
        setInnerFields(cleanedChoices);
    };

    return (
        <Box>
            <TextField
                label="질문"
                value={innerFields.question}
                onChange={(e) => {
                    const updatedChoices = { ...innerFields, question: e.target.value };
                    const cleanedChoices = cleanEmptyFields(updatedChoices);
                    setInnerFields(cleanedChoices);
                }}
                fullWidth
                margin="normal"
            />
            <TextField
                label="가운데 이미지"
                value={innerFields.image}
                onChange={(e) => {
                    const updatedChoices = { ...innerFields, image: e.target.value };
                    const cleanedChoices = cleanEmptyFields(updatedChoices);
                    setInnerFields(cleanedChoices);
                }}
                fullWidth
                margin="normal"
            />
            {innerFields.data.map((choice: any, index: number) => (
                <Box key={index} display="flex" alignItems="center" marginBottom={2} padding={1} border="1px solid #ccc">
                    <Box flexGrow={1}>
                        <TextField
                            label={`선택지 ${index + 1}`}
                            value={choice.message.data}
                            onChange={(e) => handleChoiceChange(index, "message", { data: e.target.value, meta: choice.message.meta })}
                            fullWidth
                            margin="normal"
                        />
                        <FormControl fullWidth margin="normal" className="FormControl">
                            <InputLabel>정렬</InputLabel>
                            <Select
                                label="정렬"
                                value={choice.message.meta?.alignment || ""}
                                onChange={(e) => handleMetaChange(index, e.target.value)}
                            >
                                {alignments.map((alignment) => (
                                    <MenuItem value={alignment} key={alignment}>
                                        {alignment}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            label="Object ID"
                            value={choice.objectId}
                            onChange={(e) => handleChoiceChange(index, "objectId", e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Branch ID"
                            value={choice.branchId}
                            onChange={(e) => handleChoiceChange(index, "branchId", e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                    </Box>
                    <Box marginLeft={2}>
                        <IconButton onClick={() => deleteChoice(index)}>
                            <Delete />
                        </IconButton>
                    </Box>
                </Box>
            ))}
            <Button fullWidth variant="outlined" onClick={addChoice}>
                선택지 추가
            </Button>
        </Box>
    );
};
