import React, { DragEventHandler, ReactNode, useEffect, useState } from "react";
import './SingleUploadImageDisplayed.scss';
import UploadScreen from "./UploadScreen";
import { putImage } from "../Data/Storage";
import LoadingCircle from "./LoadingCircle";

const SingleUploadImageDisplayed = ({
    path = "", width = 110, height = 110, gap = 8, screenTitle = "", screenSubtitle = [""], children = <></>,
    files, setFiles
}:{ 
    path: string,
    width: number,
    height: number,
    gap?: number | undefined,
    screenTitle?: string, 
    screenSubtitle?: string[],
    children?: ReactNode
    files: {name: string, image: string, createdAt: number}[],
    setFiles: (files: {name: string, image: string, createdAt: number}[]) => void,
}) => {
    
    const [ uploadedFile, setUploadedFile ] = useState<{name: string, image: string, createdAt: number}[]>(files || []);
    const [ onUploadScreen, setOnUploadScreen ] = useState<boolean>(false);

    const [ isFileRecognizing, setIsFileRecognizing ] = useState<boolean>(false);

    useEffect( () => {
        if (JSON.stringify(files) !== JSON.stringify(uploadedFile) ) {
            setUploadedFile(files);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[files])
    useEffect( () => {
        if (JSON.stringify(files) !== JSON.stringify(uploadedFile) ) {
            setFiles(uploadedFile);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[uploadedFile])

    const hasUploadedFile = uploadedFile.length > 0;

    const fileUploadFunction = async (FileList: FileList) => {
        setIsFileRecognizing(true);
        if ( !FileList ) {
            setIsFileRecognizing(false);
            return
        };

        let files = FileList;
        
        let image = await putImage(`/${path}/${files[0].name}`, {files} as unknown as HTMLInputElement) ?? ""; 

        setUploadedFile([{
            name: files[0].name,
            image,
            createdAt: Date.now()
        }]);

        setIsFileRecognizing(false);
    }
    
    // 업로드 핸들러
    const uploadHandler = async () => {
        const input = document.createElement('input');
        input.setAttribute('type','file');
        input.setAttribute('accept','image/*');
        input.click();

        input.addEventListener('change', async (e) => {
            if ( !input.files ) return;
            await fileUploadFunction(input.files);
        })
    }

    // 콘테이너 핸들러
    const dragOverHandler: DragEventHandler<HTMLDivElement> = (e) => {
        e.preventDefault();
        const types = e.dataTransfer.types;
        if ( types.length > 0 && types.filter( type => type !== 'Files').length < 1 ) {
            setOnUploadScreen(true);
        }
    }

    // 스크린 핸들러
    const onDropHandler: DragEventHandler<HTMLDivElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();
        
        (async () => {
            setOnUploadScreen(false);
            await fileUploadFunction(e.dataTransfer?.files);
        })()
    }
    const mouseLeaveScreenHandler: DragEventHandler<HTMLDivElement> = (e) => {
        setOnUploadScreen(false);
    }

    return(
        <div className={'MultiUploadFilenameDisplayedArea'} >
            <div className={'MultiUploadFilenameBody'} >
                <div className={'MultiUploadContainer'}
                    onClick={uploadedFile.length > 0?()=>{}:uploadHandler}
                    onDragOver={dragOverHandler}
                    style={{ width, height, cursor: 'pointer'}}
                >
                    {uploadedFile.length > 0
                        ?<div style={{position: 'relative', overflow: "hidden"}} >
                            <img 
                                src={uploadedFile[0].image} alt={uploadedFile[0].name} 
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                            />
                            <img
                                src={"https://static.webtoon.today/ddah/icon/icon_delete.svg"}
                                alt={'delete_Icon'}
                                onClick={() => {
                                    if ( !onUploadScreen ) {
                                        setUploadedFile([]);
                                    }
                                }}
                                style={{
                                    position: 'absolute',
                                    right: 5, top: 5,
                                    width: 12, height: 12,
                                    padding: 2, backgroundColor: 'black',
                                    borderRadius: '50%', cursor: 'pointer'
                                }}
                            />
                        </div>:<></>}
                    {onUploadScreen || !hasUploadedFile
                        ?<UploadScreen 
                            mouseLeaveHandler={mouseLeaveScreenHandler} 
                            onDropHandler={onDropHandler}
                            title={screenTitle}
                            subTitles={screenSubtitle}
                        />
                        :<></>
                    }
                </div>
            </div>
            {children}
            <LoadingCircle show={isFileRecognizing} />
        </div>
    )
}

export default SingleUploadImageDisplayed;