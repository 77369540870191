import { useState } from "react";

import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";

import SingleUploadImageDisplayed from "../../Component/SingleUploadImageDisplayed";
import { alignments } from "../Editor";

type Portrait = { data: string, meta: { alignment: 'left'|'center'|'right' } };
export const PortraitType: React.FC<{ fields: Portrait; path: string, setFields: (value: Portrait) => void; }> = ({ fields, path, setFields }) => {
    const [files, setFiles] = useState<{name: string; image: string; createdAt: number;}[]>([]);
    const [portrait, setPortrait] = useState(fields || {data: "", meta: {alignment: "left"}} as const);
    // TODO
    
    return <Box>
        <SingleUploadImageDisplayed
            path={path}
            width={110}
            height={110}
            screenTitle=""
            screenSubtitle={[""]}
            files={files}
            setFiles={(newFiles) => {
                const newPortrait = { data: newFiles[0]?.image, meta: { alignment: portrait.meta.alignment } };
                setFields(newPortrait);
                setPortrait(newPortrait);
                setFiles(newFiles);
            }}
        />
        <FormControl fullWidth margin="normal" className="FormControl">
            <InputLabel>Alignment</InputLabel>
            <Select
                label="Alignment"
                value={portrait.meta.alignment || 'left'}
                onChange={(e) => { 
                    setFields({ data: portrait.data, meta: {alignment: e.target.value as 'left'|'center'|'right'} });
                    setPortrait({ data: portrait.data, meta: {alignment: e.target.value as 'left'|'center'|'right'} });
                }}
            >
                {(['left', 'center', 'right'] as const).map((alignment) => (
                    <MenuItem value={alignment} key={alignment}>{alignment}</MenuItem>
                ))}
            </Select>
        </FormControl>
    </Box>
};

type Name = { data: string, meta: { alignment: 'left'|'center'|'right' } };
export const NameType: React.FC<{ fields: Name; setFields: (value: Name) => void; }> = ({ fields, setFields }) => {
    const [name, setName] = useState(fields || {data: "", meta: {alignment: "left"}});

    return <Box style={{display: 'flex'}}>
        <TextField
            label="대화창 이름"
            fullWidth
            value={name.data || ''}
            onChange={(e) => {
                const updateName = { data: e.target.value, meta: { alignment: name.meta.alignment } };
                setName(updateName);
                setFields(updateName);
            }}
            className="FormControl"
        />
        <Select
            label="Alignment"
            fullWidth
            value={name.meta?.alignment || 'left'}
            onChange={(e) => {
                const updateName = { data: name.data, meta: { alignment: e.target.value as 'left'|'center'|'right' } };
                setName(updateName);
                setFields(updateName);
            }}
        >
            {alignments.map((alignment) => (
                <MenuItem value={alignment} key={alignment}>{alignment}</MenuItem>
            ))}
        </Select>
    </Box>
};  