import React, { useEffect, useState } from 'react';
import { Box, Fab, TextField } from '@mui/material';
import { putScript } from '../Data/Storage';


import './index.scss';
import { TreeView } from './TreeView';
import { Editor } from './Editor';

const JsonEditor: React.FC = () => {
    const [episodeId, sceneId] = (window.location?.pathname?.split('/').slice(1) || []) as [`episode${number}`, `scene${number}`];
    const [objectId, setObjectId] = useState<string>(`object${1}`);
    const [branchId, setBranchId] = useState<`branch${number}`>(`branch${1}`);
    const [eventIndex, setEventIndex] = useState<number|undefined>();

    const [scenes, setScenes] = useState<{ [key: string]: SceneItem }>({});
    const [serverSent, setServerSent] = useState<{ [key: string]: SceneItem }>({});
    const [isDifferent, setIsDifferent] = useState(false);

    useEffect(() => {
        setIsDifferent(JSON.stringify(scenes) !== JSON.stringify(serverSent));
    },[scenes, serverSent])
    
    useEffect(() => {

        (async () => {
            try{
                const scenes = await fetch(`https://play.webtoon.today/${"today.webtoon.play.clumsy240909"}/${episodeId}/${sceneId}/data.json`).then(res => res.json());
                setScenes(scenes);
                setServerSent(JSON.parse(JSON.stringify(scenes)));
            } catch(e){
                console.error(e);
            }
        })();

    },[episodeId, sceneId]);


    const saveElements = (elements: Partial<EventItem>) => {
    
        if (Object.keys(elements).length === 0) return;

        if (eventIndex !== undefined){
            const updatedScenes = JSON.parse(JSON.stringify(scenes));

            updatedScenes[sceneId][objectId][branchId].data[eventIndex] = elements as EventItem;
            setScenes(updatedScenes);
            setEventIndex(undefined);
            return;
        }
    
        setScenes((prev) => {
            const updatedScenes = { ...prev };
    
            if (!updatedScenes[sceneId]) {
                updatedScenes[sceneId] = {  };
            }
    
            if (!(objectId in updatedScenes[sceneId])) {
                updatedScenes[sceneId][objectId] = { [branchId]: { data: [] as EventItem[]} } as ObjectItem;
            }
    
            const existingBranch = updatedScenes[sceneId][objectId][branchId];

            if (!existingBranch) {
                updatedScenes[sceneId][objectId][branchId] = { data: [elements]};
            } else {    
                updatedScenes[sceneId][objectId][branchId].data.push(elements);
            }
            
            return updatedScenes;
        });
    };

    return (
        <Box className="JsonEditor">
            <Box className="InputForm">
                <Box className="SceneObjectBranchSection">
                    <TextField label="Scene ID" name="sceneId" value={sceneId} className="FormControl" disabled />
                    <TextField label="Object ID" name="objectId"
                        value={objectId}
                        onChange={(e)=> setObjectId(e.target.value as `object${number}`)}
                        className="FormControl"
                        required
                    />
                    <TextField
                        label="Branch ID"
                        name="branchId"
                        value={branchId}
                        onChange={(e) => setBranchId(e.target.value as `branch${number}`)}
                        className="FormControl"
                        required
                    />
                </Box>
                <Editor {...{
                    episodeId, sceneId, objectId, branchId, eventIndex, saveElements,
                    elements: eventIndex!==undefined?scenes[sceneId][objectId][branchId].data[eventIndex]:{}
                }} />
            </Box>
            <Box className="TreeViewContainer">
                <TreeView
                    style={{ width: '100%', height: '100%' }}
                    {...{sceneId, scenes, setScenes, objectId, branchId, setObjectId, setBranchId, eventIndex, setEventIndex}}
                />
            </Box>
            <Fab
                style={{position: 'fixed', right: 40, bottom: 40}}
                color={isDifferent ? 'primary' : 'default'}
                onClick={async () => {
                    await putScript(`/${episodeId}/${sceneId}`, JSON.stringify(scenes))
                    setServerSent(JSON.parse(JSON.stringify(scenes)));
                }}
            >Save</Fab>
        </Box>
    );
};



export default JsonEditor;
