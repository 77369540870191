import { Delete } from "@mui/icons-material";
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useState } from "react";

type Dialog = { title: string, description: string, imagePath: string, actions: { message: { data: string, meta: { alignment: 'left'|'center'|'right' } }, branchId: string }[] };
export const DialogType: React.FC<{ fields: Dialog; setFields: (value: Dialog) => void; }> = ({ fields, setFields }) => {

    const [dialog, setDialog] = useState<Dialog>({ title: '', description: '', imagePath: '', actions: [] });
    
    const handleActionChange = (index: number,  value: any) => {
        const updatedActions = [...dialog.actions];
        updatedActions[index] = value;
        setDialog({ ...dialog, actions: updatedActions });
        setFields({ ...dialog, actions: updatedActions });
    };

    const addAction = () => {
        const updatedActions = [...dialog.actions, { message: { data: '', meta: { alignment: 'left' } }, branchId: '' } as const];
        setDialog({ ...dialog, actions: updatedActions });
        setFields({ ...dialog, actions: updatedActions });
    };

    return (
        <Box>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <TextField
                    label="Title"
                    value={dialog.title || ''}
                    onChange={(e) => {
                        setDialog({...dialog, title: e.target.value});
                        setFields({...dialog, title: e.target.value});
                    }}
                    className="FormControl"
                />
                <TextField
                    label="Description"
                    value={dialog.description || ''}
                    onChange={(e) => {
                        setDialog({...dialog, description: e.target.value});
                        setFields({...dialog, description: e.target.value})
                    }}
                    className="FormControl"
                />
                <TextField
                    label="Image Path"
                    value={dialog.imagePath || ''}
                    onChange={(e) => {
                        setDialog({...dialog, imagePath: e.target.value});
                        setFields({...dialog, imagePath: e.target.value})
                    }}
                    className="FormControl"
                />
            </div>
            {dialog.actions.map((action: any, index: number) => (
                <Box key={index} display="flex" alignItems="center" marginBottom={2} padding={1} border="1px solid #ccc">
                    <Box flexGrow={1}>
                        <TextField
                            label="제목"
                            value={action.message.data}
                            onChange={(e) => handleActionChange(index, {...action, message: { ...action.message, data: e.target.value }})}
                            fullWidth
                            margin="normal"
                        />
                        <FormControl fullWidth margin="normal">
                            <InputLabel>제목 정렬</InputLabel>
                            <Select
                                label="정렬"
                                value={action.message.meta?.alignment || 'left'}
                                onChange={(e) => handleActionChange(index, {...action, message: { ...action.message, meta: { alignment: e.target.value } }} )}
                            
                            >
                                {['left', 'center', 'right'].map((alignment) => (
                                    <MenuItem value={alignment} key={alignment}>{alignment}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            label="Branch ID"
                            value={action.branchId}
                            onChange={(e) => handleActionChange(index, {...action, branchId: e.target.value } )}
                            fullWidth
                            margin="normal"
                        />
                    </Box>
                    <Box marginLeft={2}>
                        <IconButton onClick={() => { /* Optional: Add Delete Functionality */ }}>
                            <Delete />
                        </IconButton>
                    </Box>
                </Box>
            ))}
            <Button fullWidth variant="outlined" onClick={addAction}>
                Add Action
            </Button>
        </Box>
    );
};