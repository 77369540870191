import { Add, DeleteForever } from "@mui/icons-material";
import { Box, Checkbox, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useState } from "react";

type Emotion = { [key: string]: string };
export const EmotionType: React.FC<{ fields: Emotion; setFields: (value: Emotion) => void; }> = ({ fields, setFields }) => {
    const [newKey, setNewKey] = useState('');
    const [newValue, setNewValue] = useState('');
    
    const removeField = (key: string) => {
        const updatedFields = { ...fields };
        delete updatedFields[key];
        setFields(updatedFields);
    };

    return <Box>
        {Object.entries(fields || {}).map(([key,value], idx) => (
            <div style={{display: "flex", flexDirection: "row", flexWrap: "nowrap"}} key={key}>
                <FormControl fullWidth margin="normal" className="FormControl">
                    <TextField
                        disabled
                        label={"오브젝트"}
                        value={key || ''}
                    />
                </FormControl>
                <FormControl fullWidth margin="normal" className="FormControl">
                    <TextField
                        disabled
                        label={"타겟"}
                        value={(fields || {})[key] || ''}
                    />
                </FormControl>
                <IconButton onClick={() => removeField(key)}><DeleteForever/></IconButton>
            </div>
        ))}
        <div style={{display: "flex", flexDirection: "row", flexWrap: "nowrap",}}>
            <FormControl fullWidth margin="normal" className="FormControl">
                <TextField
                    label={"캐릭터id"}
                    value={newKey}
                    onChange={(e) => {
                        setNewKey(e.target.value);
                    }}
                />
            </FormControl>
            
            <FormControl fullWidth margin="normal">
                <InputLabel>행동</InputLabel>
                <Select
                    label="행동"
                    value={newValue}
                    onChange={(e) => {
                        setNewValue(e.target.value);
                    } }
                >
                    {[
                        "sad", "angry", "normal", "surprise", "happy",
                        "sit", "lay", "fall",
                        "watch NW", "watch NE", "watch SW", "watch SE",
                        "raiseRightHand", "dropRightHand"
                    ].map((alignment) => (
                        <MenuItem value={alignment} key={alignment}>{alignment}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            
            <IconButton color={!!newValue?'primary':undefined} onClick={() => {
                setFields({...fields, [newKey]: newValue});
                setNewKey(''); setNewValue('');
            }}>
                <Add/>
            </IconButton>
        </div>
        <label style={{fontSize: '60%'}}>{"새 항목을 입력한 후 저장 버튼을 꼭 눌러주세요"}</label>
    </Box>
};

type Zoom = { target: string, zoom: number };
export const ZoomType: React.FC<{ fields: Zoom; setFields: (value: Zoom) => void; }> = ({ fields, setFields }) => (
    <Box>
        <TextField
            label="Target"
            value={fields?.target || ''}
            onChange={(e) => setFields({...fields, target: e.target.value})}
            className="FormControl"
        />
        <TextField
            label="Zoom"
            type="number"
            value={fields?.zoom || ''}
            onChange={(e) => setFields({...fields, zoom: parseInt(e.target.value, 10)})}
            className="FormControl"
        />
    </Box>
);

type InEffect = { backdrop: boolean, shake: boolean, isBottomMenuOpen: boolean, isTopMenuOpen: boolean, fadeIn: boolean, whiteIn: boolean };
export const InEffectType: React.FC<{ fields: InEffect; setFields: (value: InEffect) => void; }> = ({ fields, setFields }) => {

    return <Box>
        {(['backdrop', 'shake', 'isBottomMenuOpen', 'isTopMenuOpen', 'fadeIn', 'whiteIn'] as const).map((effect) => (
            <FormControl fullWidth margin="normal" key={effect} className="FormControl">
                <InputLabel>{effect}</InputLabel>
                <Select
                    label={effect}
                    value={(fields ||{})[effect] || ''}
                    onChange={(e) => {
                        let newField = { ...fields, [effect]: e.target.value };

                        if (e.target.value === '') {
                            delete newField[effect];
                        }
                        setFields(newField);
                    }}
                >
                    <MenuItem value={''}>None</MenuItem>
                    <MenuItem value={'true'}>True</MenuItem>
                    <MenuItem value={'false'}>False</MenuItem>
                </Select>
            </FormControl>
        ))}
    </Box>
};

type OutEffect = { fadeOut: boolean, whiteOut: boolean };
export const OutEffectType: React.FC<{ fields: OutEffect; setFields: (value: OutEffect) => void; }> = ({ fields, setFields }) => (
    <Box>
        {(['fadeOut', 'whiteOut'] as const).map((effect) => (
            <FormControl fullWidth margin="normal" key={effect} className="FormControl">
                <InputLabel>{effect}</InputLabel>
                <Select
                    label={effect}
                    value={(fields ||{})[effect] || ''}
                    onChange={(e) => {
                        let newField = { ...fields, [effect]: e.target.value };

                        if (e.target.value === '') {
                            delete newField[effect];
                        }
                        setFields(newField);
                    }}
                >
                    <MenuItem value={''}>None</MenuItem>
                    <MenuItem value={'true'}>True</MenuItem>
                    <MenuItem value={'false'}>False</MenuItem>
                </Select>
            </FormControl>
        ))}
    </Box>
);

type Visibility = Record<string, 'show'|'hide'>;
export const VisibilityType: React.FC<{ fields: Visibility; setFields: (value: Visibility) => void; }> = ({ fields, setFields }) => {
    const [newKey, setNewKey] = useState('');
    const [newValue, setNewValue] = useState<'show'|'hide'>('show');

    const removeField = (key: string) => {
        const updatedFields = { ...fields };
        delete updatedFields[key];
        setFields(updatedFields);
    };

    return <Box>
        {Object.entries(fields || {}).map(([key,value], idx) => (
            <div style={{display: "flex", flexDirection: "row", flexWrap: "nowrap"}} key={key}>
                <FormControl fullWidth margin="normal" className="FormControl">
                    <TextField
                        disabled
                        label={"오브젝트"}
                        value={key || ''}
                    />
                </FormControl>
                <FormControl fullWidth margin="normal" className="FormControl">
                    <Checkbox disabled checked={value === 'show'}/>
                </FormControl>
                <IconButton onClick={() => removeField(key)}><DeleteForever/></IconButton>
            </div>
        ))}
        
        <div style={{display: "flex", flexDirection: "row", flexWrap: "nowrap"}}>
            <FormControl fullWidth margin="normal" className="FormControl">
                <TextField
                    label={"오브젝트"}
                    value={newKey}
                    onChange={(e) => {
                        setNewKey(e.target.value);
                    }}
                />
            </FormControl>
            <FormControl fullWidth margin="normal" className="FormControl" style={{display:'flex', alignItems: 'center', justifyContent: 'center'}} >
                <InputLabel>{"보이기/숨기기"}</InputLabel>
                <Checkbox
                    checked={newValue === 'show'}
                    onChange={(e) => setNewValue(e.target.checked ? 'show' : 'hide')}
                />
            </FormControl>
            
            <IconButton color={!!newValue?'primary':undefined} onClick={() => {
                setFields({...fields, [newKey]: newValue});
                setNewKey(''); setNewValue('show');
            }}>
                <Add/>
            </IconButton>
        </div>
    </Box>
};

export const KeyAndValueType: React.FC<{ fields: { [key: string]: string }; setFields: (value: any) => void; }> = ({ fields, setFields }) => {
    const [newKey, setNewKey] = useState('');
    const [newValue, setNewValue] = useState('');

    const removeField = (key: string) => {
        const updatedFields = { ...fields };
        delete updatedFields[key];
        setFields(updatedFields);
    };

    return <Box>
        {Object.entries(fields || {}).map(([key,value], idx) => (
            <div style={{display: "flex", flexDirection: "row", flexWrap: "nowrap"}} key={key}>
                <FormControl fullWidth margin="normal" className="FormControl">
                    <TextField
                        disabled
                        label={"오브젝트"}
                        value={key || ''}
                    />
                </FormControl>
                <FormControl fullWidth margin="normal" className="FormControl">
                    <TextField
                        disabled
                        label={"타겟"}
                        value={(fields || {})[key] || ''}
                    />
                </FormControl>
                <IconButton onClick={() => removeField(key)}><DeleteForever/></IconButton>
            </div>
        ))}
        
        <div style={{display: "flex", flexDirection: "row", flexWrap: "nowrap"}}>
            <FormControl fullWidth margin="normal" className="FormControl">
                <TextField
                    label={"오브젝트"}
                    value={newKey}
                    onChange={(e) => {
                        setNewKey(e.target.value);
                    }}
                />
            </FormControl>
            <FormControl fullWidth margin="normal" className="FormControl">
                <TextField
                    label={"타겟"}
                    value={newValue}
                    onChange={(e) => setNewValue(e.target.value)}
                />
            </FormControl>
            
            <IconButton color={!!newValue?'primary':undefined} onClick={() => {
                setFields({...fields, [newKey]: newValue});
                setNewKey(''); setNewValue('');
            }}>
                <Add/>
            </IconButton>
        </div>
    </Box>
};