import { StopCircle } from "@mui/icons-material";
import { IconButton, MenuItem, Select, Slider, TextField } from "@mui/material";

export const SingleStringType: React.FC<{ fieldType: ElementType; fields: string; setFields: (value: any) => void; }> = ({ fieldType, fields, setFields }) => (
    <TextField
        label={fieldType}
        value={fields as string || ""}
        onChange={(e) => setFields(e.target.value)}
        className="FormControl"
    />
);

const BGMs: string[] = [
    "Sound/BGM/1. Episode Theme",
    "Sound/BGM/2. Game Theme",
    "Sound/BGM/3. Emotion theme_anger",
    "Sound/BGM/3. Emotion theme_happiness",
    "Sound/BGM/3. Emotion theme_peaceful",
    "Sound/BGM/3. Emotion theme_rough",
    "Sound/BGM/3. Emotion theme_sadness",
    "Sound/BGM/3. Emotion theme_suspicion",
    "Sound/BGM/4. character theme_구하나",
    "Sound/BGM/4. Character theme_신리리",
    "Sound/BGM/4. Character theme_지여준",
    "Sound/BGM/Acoustic-major-129bpm-curious-calm",
    "Sound/BGM/Acoustic-major-138bpm-exciting-daily",
    "Sound/BGM/Orchestra-major-117bpm-gloomy-daily",
    "Sound/BGM/Orchestra-major-89bpm-dramatic-mystery",
    "Sound/BGM/Orchestra-minor-129bpm-silent-mystery"
];

export const BGMType: React.FC<{ fieldType: ElementType; fields: string; setFields: (value: any) => void; }> = ({ fieldType, fields, setFields }) => (
    <Select
        label={fieldType}
        value={fields as string || ""}
        onChange={(e) => setFields(e.target.value)}
        className="FormControl"
    >
        {BGMs.map((bgm: string) => <MenuItem key={bgm} value={bgm}>{bgm.split('/').reverse()[0]}</MenuItem>)}
    </Select>
);

const SFXs: string[] = [
    "고통_남자",
    "군중_대형", "군중_중형", "군중_학교",
    "글씨쓰기",
    "기침_남자",
    "끼이익_나무문", "노크_작은문", "노크_큰문",
    "문고리 소리", "문소리_닫기", "문소리_열기",
    "물건 부서짐_우당탕",
    "발소리_나무바닥_무거운", "발소리_짧은 1회",
    "비웃음_남자", "비웃음_여자",
    "빗소리_보슬보슬", "빗소리_부슬부슬",
    "삐_느린", "삐_빠른", "삐_클릭",
    "삑_기본",
    "숨소리_작은", "숨소리_크고 깊은",
    "스위치_가벼운, 서브, 설정", "스위치_기본", "스위치_위로 올라오는",
    "심장박동_기본", "심장박동_빠르고 일정한",
    "웃음_남자",
    "유리 깨지는 소리_대형",
    "저주파_좌우",
    "충격파_추락_대형", "충격파_추락_빈공간", "충격파_쿵_낮고 긴", "충격파_쿵_물건 떨어지는",
    "코인_충전",
    "클릭_긴", "클릭_짧은",
    "타자기_빠른",
    "탁_아이템 내려 놓는 소리", "툭_가벼운", "페이지턴_팔랑",
    "핑거스냅",
    "한숨_남자", "한숨_여자",
    "헛기침_남자", "헛기침_여자",
    "효과음_꿈꾸는",
    "효과음_불길한, 빈공간",
    "효과음_섬뜩한",
    "휙_가벼운", "휙_다음으로 넘김", "휙_빠르게 넘김", "휙_스쳐지나가는",
    "휴대전화 진동",
];

export const SFXType: React.FC<{ fieldType: ElementType; fields: string; setFields: (value: any) => void; }> = ({ fieldType, fields, setFields }) => (
    <Select
        label={fieldType}
        value={fields as string || ""}
        onChange={(e) => setFields(e.target.value)}
        className="FormControl"
    >
        {SFXs.map((sfx: string) => <MenuItem key={sfx} value={sfx}>{sfx.split('/').reverse()[0]}</MenuItem>)}
    </Select>
);

export const TimeOutType: React.FC<{ fieldType: ElementType; fields: number; setFields: (value: any) => void; }> = ({ fieldType, fields, setFields }) => (
    <div>
        <div style={{display: 'flex', alignItems:'flex-start'}}>
            <Slider
                defaultValue={fields || 0}
                onChange={(e,value) => setFields(value)}
                marks={[
                    {value: 50, label: '아주 짧음'},
                    {value: 500, label: '보통'},
                    {value: 1000, label: '약간 긴 텀'}
                ]}
                min={0} max={2000}
                step={50}
            />
            <IconButton onClick={() => setFields(999999)}><StopCircle/></IconButton>
        </div>
        <label style={{fontSize: '0.8rem'}}>{`${fields}ms: ${fields < 100?'아주 짧음' : fields < 500?'보통' : fields < 1000?'약간 긴 텀': '긴 텀'}`}</label>
    </div>
);