import { DragEventHandler } from "react";
import './UploadScreen.scss';

const UploadScreen = ({
    mouseLeaveHandler, onDropHandler, 
    title = "마우스로 파일을 끌어서 넣을 수 있어요",
    subTitles
}:{
    mouseLeaveHandler: DragEventHandler<HTMLDivElement>,
    onDropHandler: DragEventHandler<HTMLDivElement>,
    title?: string, subTitles: string[]
}) => {

    return(
        <div className={'UploadScreenBackground'} >
            <img src={'https://static.webtoon.today/ddah/icon/icon_uploadImageIcon.png'} alt={'uploadImageIcon'} width={36} height={40} />
            <div className={'ScreenTitle'} >
                {title}
            </div>
            <div className={'ScreenSubTitle'} >
                {subTitles.map( text => (
                    <div key={text}>{text}</div>
                ))}
            </div>
            <div className={'UploadScreen'} onDragLeave={mouseLeaveHandler} onDrop={onDropHandler} />
        </div>
    )
}
export default UploadScreen;