import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Select,} from "@mui/material";
import { CSSProperties, useEffect, useState } from "react";

import { BGMType, SFXType, SingleStringType, TimeOutType } from './EventElement/SingleString';
import { PortraitType, NameType } from './EventElement/MessageWithMeta';
import { EmotionType, ZoomType, InEffectType, OutEffectType, VisibilityType } from './EventElement/KeyValue';
import { DialogType } from './EventElement/Dialog';
import { MinigameType } from './EventElement/Minigame';
import { ChoiceType } from './EventElement/Choice';
import { ImageType } from "./EventElement/Image";
import { ChatType } from "./EventElement/Chat";
import { Delete } from "@mui/icons-material";

export const alignments = [
    "upperLeft", "upperCenter", "upperRight",
    "middleLeft", "middleCenter", "middleRight",
    "lowerLeft", "lowerCenter", "lowerRight"
]

export const Editor = ({
    episodeId, sceneId, objectId, branchId,
    elements: givenElements, saveElements, style
}:{
    episodeId: string, sceneId: string, objectId: string, branchId: string,
    elements: Partial<EventItem>, saveElements: (elements: Partial<EventItem>)=>void, style?: CSSProperties
}) => {
    const [elements, setElements] = useState<Partial<EventItem>>({});
    useEffect(() => {
        setElements(givenElements);
    }, [givenElements]);

    const [fieldType, setFieldType] = useState<ElementType | ''>('');
    const [fields, setFields] = useState<any>(undefined);
    
    const addElement = () => {
        if (!sceneId || !objectId || !branchId || !fieldType) return;

        setElements(elements => ({...elements, [fieldType]: fields}));
        setFieldType('');
        setFields(undefined);
    };

    const path = `${episodeId}/${sceneId}`;

    const renderElementForm = () => {
        switch (fieldType) {
            case 'background':
                return <ImageType        {...{path, fieldType, setFields, fields}} />;
            case 'logs':
                return <SingleStringType {...{path, fieldType, setFields, fields}} />;
            case 'BGM':
                return <BGMType          {...{path, fieldType, setFields, fields}} />;
            case 'SFX':
                return <SFXType          {...{path, fieldType, setFields, fields}} />;
            case 'portrait':
                return <PortraitType     {...{path, fieldType, setFields, fields}} />;
            case 'minigame':
                return <MinigameType     {...{path, fieldType, setFields, fields}} />;
            case 'choice':
                return <ChoiceType       {...{path, fieldType, setFields, fields}} />;
            case 'position':
            case 'moves':
            case 'patrols':
            case 'visibility':
                return <VisibilityType   {...{path, fieldType, setFields, fields}} />;
            case 'emotion':
                return <EmotionType      {...{path, fieldType, setFields, fields}} />;
            case 'zoom':
                return <ZoomType         {...{path, fieldType, setFields, fields}} />;
            case 'inEffect':
                return <InEffectType     {...{path, fieldType, setFields, fields}} />;
            case 'outEffect':
                return <OutEffectType    {...{path, fieldType, setFields, fields}} />;
            case 'name':
                return <NameType         {...{path, fieldType, setFields, fields}} />;
            case 'dialog':
                return <DialogType       {...{path, fieldType, setFields, fields}} />;
            case 'chat':
                return <ChatType         {...{path, fieldType, setFields, fields}} />;
            case 'timeout':
                return <TimeOutType      {...{path, fieldType, setFields, fields}} />;
            default:
                return null;
        }
    };


    return <Box className="Editor" style={style}>
        <FormControl fullWidth margin="normal" className="ElementTypeSelectionSection">
            <InputLabel>Element Type</InputLabel>
            <Select
                label="Element Type"
                name="elementType"
                value={fieldType}
                onChange={(e) => {
                    setFields(undefined);
                    setFieldType(e.target.value as ElementType)
                }}
            >
                {([
                    'background', 'portrait', 'name', 'chat',
                    'minigame', 'logs', 'BGM', 'SFX',
                    'choice',
                    'inEffect', 'outEffect', 'position', 'moves', 'patrols', 'emotion', 'zoom', 'visibility',
                    'dialog', 'timeout'
                ] as ElementType[]).map((type) => (
                    <MenuItem value={type} key={type}>{type}</MenuItem>
                ))}
            </Select>
        </FormControl>
        <Box className="FieldsSection">
            {renderElementForm()}
        </Box>
        <Button
            fullWidth variant="contained"
            disabled={fieldType === ''}
            onClick={addElement}
            className="AddElementButton"
        >
            특성 추가
        </Button>
        <Box className="ElementsPreview">
            {Object.entries(elements || {}).map(([type, element], index) => (
                <Box key={index} display="flex" flexDirection="row" marginBottom={2} padding={2} border="1px solid #ccc" >
                    <Box key={index} flex="1" display="flex" flexDirection="column"  >
                        <strong>Type: {type}</strong>
                        {(typeof element === "string" || typeof element === "number") ? element
                        : Object.entries(element || {}).map(([key, value]) => (
                            key !== 'type' && <Box key={key}>{`${key}: ${JSON.stringify(value)}`}</Box>
                        ))}
                    </Box>
                    <Box width={35} >
                        <IconButton onClick={() => {
                            const filteredElements = Object.fromEntries(Object.entries(elements).filter((_:any, idx:number) => idx !== index));
                            setElements(filteredElements);
                        }}>
                            <Delete />
                        </IconButton>
                    </Box>
                </Box>
            ))}
        </Box>
        <Button
            fullWidth variant="contained"
            disabled={Object.keys(elements).length === 0}
            onClick={() => {
                saveElements(elements);
                setElements({});
                setFieldType('');
                setFields(undefined);
            }}
            className="SaveElementsButton"
        >
            이벤트 구성 완료
        </Button>
    </Box>;
    
};