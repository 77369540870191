import { useState } from "react";

import { Box, TextField } from "@mui/material";


export const MinigameType: React.FC<{ fields: any; setFields: (value: any) => void; }> = ({ fields, setFields }) => {
    const [minigame, setMinigame] = useState(fields || {name: '', nextBranchId: ''});

    return <Box>
        <TextField
            label="Name"
            value={minigame.name || ''}
            onChange={(e) => {
                const newMinigame = { name: e.target.value, nextBranchId: minigame.nextBranchId };
                setMinigame(newMinigame);
                setFields(newMinigame);
            }}
            className="FormControl"
        />
        <TextField
            label="Next Branch ID"
            value={minigame.nextBranchId || ''}
            onChange={(e) => {
                const newMinigame = { name: minigame.name, nextBranchId: e.target.value };
                setMinigame(newMinigame);
                setFields(newMinigame);
            }}
            className="FormControl"
        />
    </Box>
};