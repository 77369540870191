import { Button } from '@mui/material';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
import { CSSProperties } from 'react';


export const TreeView = ({
    scenes, setScenes, sceneId,
    style,
    objectId: givenObjectId, branchId: givenBranchId,
    setObjectId, setBranchId,
    eventIndex, setEventIndex
}:{
    scenes: { [key: string]: SceneItem }, setScenes: (newScenes: { [key: string]: SceneItem })=>void, sceneId: string,
    style?: CSSProperties,
    objectId: string, branchId: `branch${number}`,
    setObjectId: (objectId: string) => void, setBranchId: (branchId: `branch${number}`) => void,
    eventIndex: number|undefined, setEventIndex: (eventIndex: number|undefined) => void
}) => {
    

    const renderTree = (sceneId: string) => {
        const scene = scenes[sceneId];
        return (
            <TreeItem key={sceneId} itemId={sceneId} label={`Scene: ${sceneId}`} style={style}>
                {Object.entries(scene).filter(([key,]) => key !== 'id').map(([objectId, object]) => (
                    <TreeItem key={objectId} itemId={objectId} label={`Object: ${objectId}`}>
                        {Object.entries(object)
                            .filter(([key,branch]) => key !== 'id' && typeof branch === 'object' && branch !== null && 'data' in branch)
                            .map(([branchId, branch]) => {
                            
                            const branchObj = branch as { data: any[] };
                            const data = branchObj.data;

                            return(
                            <TreeItem key={branchId} itemId={`${objectId}-${branchId}`} label={`Branch: ${branchId}`}>
                                {Array.isArray(data) && data.length > 0 &&
                                    data.map((content, index) => (
                                        <TreeItem
                                            key={`${objectId}-${branchId}-content-${index}`}
                                            itemId={`${objectId}-${branchId}-content-${index}`}
                                            label={`Event ${index}:\n${Object.entries(content).map(([key, value]) => `${key}: ${JSON.stringify(value)}`).join(',\n')}`}
                                            style={{
                                                ...(givenObjectId === objectId && givenBranchId === branchId && index === eventIndex ? {backgroundColor: "rgb(222,222,222)"}:{})
                                                ,whiteSpace: 'pre-line'
                                            }}
                                            
                                        >
                                            {givenObjectId === objectId && givenBranchId === branchId && index === eventIndex ?
                                                <div style={{display: 'flex'}}>
                                                    <Button fullWidth onClick={() => {
                                                        let newScenes = JSON.parse(JSON.stringify(scenes));
                                                        newScenes[sceneId][objectId][branchId as `branch${number}`].data.splice(index, 0, {});
                                                        setScenes(newScenes);
                                                    }}>{"항목을 위에 추가"}</Button>
                                                    <Button fullWidth onClick={() => {
                                                        setEventIndex(undefined);
                                                    }}>{"선택해제"}</Button>
                                                    <Button fullWidth color={"secondary"} onClick={() => {
                                                        let newScenes = JSON.parse(JSON.stringify(scenes));
                                                        newScenes[sceneId][objectId][branchId as `branch${number}`].data.splice(index, 1);
                                                        setScenes(newScenes);
                                                        setEventIndex(undefined);
                                                    }}>{"삭제"}</Button>
                                                </div>
                                                :<Button fullWidth onClick={() => {
                                                    setObjectId(objectId);
                                                    setBranchId(branchId as `branch${number}`);
                                                    setEventIndex(index);
                                                }}>
                                                    {"선택"}
                                                </Button>}
                                        </TreeItem>
                                    ))}
                            </TreeItem>)
                        
                        })}
                    </TreeItem>
                ))}
            </TreeItem>
        );
    };
    
    return <SimpleTreeView>
        {Object.keys(scenes).map((sceneId) => renderTree(sceneId))}
    </SimpleTreeView>;
}