import React from 'react';
import './App.css';

import Home from './Page';

function App() {
    return (
        <Home />
    );
}

export default App;
