import { useState } from "react";

import SingleUploadImageDisplayed from "../../Component/SingleUploadImageDisplayed";

export const ImageType: React.FC<{ path: string, fields: string; setFields: (value: any) => void; }> = ({ path, fields, setFields }) => {
    const [files, setFiles] = useState<{name: string; image: string; createdAt: number;}[]>(fields?[{name: "", image: fields as string, createdAt: 0}]:[]);

    return <SingleUploadImageDisplayed
        path={path}
        width={110}
        height={110}
        screenTitle=""
        screenSubtitle={[""]}
        files={files}
        setFiles={(newFiles) => {
            console.log(newFiles);
            setFields(newFiles[0]?.image);
            setFiles(newFiles);
        }}
    />;
};
